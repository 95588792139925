@import "../main/abstracts/index";

section.section--intro{

    &.section--padding-top.section--padding-bottom{
        padding-top: 60px;
        padding-bottom: 80px;
    }

    .row .row{
        justify-content: space-between;
    }

    h1{
        color: $color__primary;
        margin-bottom: 0;
    }

    .col-lg-5{
        margin-top: 40px;

        @include media-breakpoint-up(lg) {
            margin-top: -200px;
            margin-bottom: -150px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: -207px;
        }

        > div{
            z-index: 1;
            position: relative;
            box-shadow: 0 2px 8px rgba(0,0,0,.4);
            background-color: $color__tertiary;

            @include media-breakpoint-up(lg) {
                max-width: 535px;
                margin-left: 0;
            }

            @include media-breakpoint-up(xl) {
                margin-left: #{map-get-last-value($container-padding-sizes)};
            }

            @media (min-width: 1500px) {
                max-width: 470px;
                margin-left: calc(#{map-get-last-value($container-padding-sizes)} + 65px);
            }
        }
    }

    .section--heading .container-xxl{
        width: 100%;
        min-width: unset;
        max-width: unset;
        position: relative;

        padding: 25px 22px 0 22px;

        @media (max-width: 400px) {
            padding: 25px 20px 0 20px;
        }

        @include media-breakpoint-up(lg) {
            padding: 35px 32px 0 32px;
        }
    }
}

section.section--external{

    &.section--padding-top.section--padding-bottom{
        padding:0;
    }

    .container-xxl{
        padding-right: 0;
        padding-left: 0;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }

    .kc_wrapper{

        .kc{

            &_form{
                width: 100%;
                min-width: unset;
                max-width: unset;

                position: relative;
                background-color: $color__tertiary;
                padding: 5px 22px 7px 22px;

                @include media-breakpoint-up(lg) {
                    min-height: 500px;
                }

                @media (max-width: 400px) {
                    padding: 5px 20px 7px 20px;
                }

                .kc_page_header{
                    padding: 0;
                    background-color: transparent;
                }
            }
        }
    }
}
